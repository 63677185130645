import { Heading, Flex, Text, useMatchBreakpointsContext } from '@pancakeswap/uikit'

const StatCardContent: React.FC<{ headingText: string; bodyText: string; highlightColor: string }> = ({
  headingText,
  bodyText,
  highlightColor,
}) => {
  // const { isMobile, isTablet } = useMatchBreakpointsContext()
  // const isSmallerScreen = isMobile || isTablet
  const split = headingText.split(' ')
  const lastWord = split.pop()
  const remainingWords = split.slice(0, split.length).join(' ')

  return (
    <Flex
      minHeight={['220px', null, '248px']}
      minWidth="232px"
      width="100%"
      flexDirection="column"
      justifyContent="flex-end"
    >
      <Text fontWeight={700} lineHeight="108%" fontSize={['24px', null, '30px']}>
        {remainingWords}
      </Text>
      <Text
        as="p"
        color={highlightColor}
        mb="21px"
        textTransform="capitalize"
        fontSize={['24px', null, '30px']}
        fontWeight={700}
        lineHeight="108%"
      >
        {lastWord}
      </Text>
      <Text color="textSubtle" fontSize={['18px', null, '20px']}>
        {bodyText}
      </Text>
    </Flex>
  )
}

export default StatCardContent
