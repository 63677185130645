import { Flex, Text, Button } from '@pancakeswap/uikit'
import { NextLinkFromReactRouter as RouterLink } from 'components/NextLink'
import { useTranslation } from '@pancakeswap/localization'

import { CompositeImageProps } from '../CompositeImage'
import ColoredWordHeading from '../ColoredWordHeading'

interface EarnSectionButton {
  to: string
  text: string
  external: boolean
}

export interface EarnSectionProps {
  headingText: string
  bodyText: any
  reverse: boolean
  primaryButton: EarnSectionButton
  secondaryButton?: EarnSectionButton
  images?: CompositeImageProps
  image: string
  imageWidth?: string
}

const EarnSection: React.FC<EarnSectionProps> = (props) => {
  const { t } = useTranslation()

  const { headingText, bodyText, reverse, primaryButton, secondaryButton, images, image, imageWidth } = props

  return (
    <Flex flexDirection="column" position="relative">
      <Flex flexDirection={['column', null, reverse ? 'row-reverse' : 'row']} style={{ rowGap: '50px' }}>
        <Flex flexDirection="column" flex={2}>
          <Flex
            height={['250px', null, '100%']}
            width={['250px', null, '100%']}
            maxWidth={[null, null, '260px', '100%']}
            flex={[null, null, null, '1']}
            mb={['24px', null, '0']}
          >
            <img src={image} alt="" width={imageWidth} />
          </Flex>
          <ColoredWordHeading
            text={headingText}
            fontWeight="700 !important"
            mb="12px"
            fontSize={['22px', null, null, '24px', '26px']}
          />
          <Text color="textSubtle" mb="24px" fontSize={['16px', null, null, '16px', '18px']} fontWeight={500}>
            {bodyText}
          </Text>
        </Flex>

        <Flex
          flexDirection="column"
          flex="1"
          ml={[null, null, !reverse && '30px']}
          mr={[null, null, reverse && '30px']}
          alignSelf={['flex-start', null, 'flex-start']}
          width="100%"
        >
          <ColoredWordHeading
            text={t('Liquid Staking')}
            fontWeight="700 !important"
            mb="12px"
            fontSize={['22px', null, null, '24px', '26px']}
          />
          <Text
            color="textSubtle"
            mb="24px"
            fontSize={['16px', null, null, '16px', '20px']}
            fontWeight={500}
            height={['30px', null, '120px', '120px', '100px']}
          >
            {t('Earn rewards while retaining asset flexibility')}
          </Text>

          <Flex flexDirection="column">
            <Flex
              height={['250px', null, '100%']}
              width={['250px', null, '100%']}
              maxWidth={[null, null, '260px', '100%']}
              flex={[null, null, null, '1']}
              mb={['24px', null, '0']}
            >
              <img width="100%" src="/images/home/farm-3.png" alt="" />
            </Flex>

            <Button mr={['16px', null, '32px']}>
              <RouterLink to="/liquidity">
                <Text color="card" fontSize={['14px', null, '16px']}>
                  {t('Add Now')}
                </Text>
              </RouterLink>
            </Button>
          </Flex>
        </Flex>

        <Flex
          flexDirection="column"
          flex="1"
          ml={[null, null, !reverse && '30px']}
          mr={[null, null, reverse && '30px']}
          alignSelf={['flex-start', null, 'flex-start']}
          width="100%"
        >
          <ColoredWordHeading
            text={t('Pools')}
            fontWeight="700 !important"
            mb="12px"
            fontSize={['22px', null, null, '24px', '26px']}
          />
          <Text
            color="textSubtle"
            mb="24px"
            fontSize={['16px', null, null, '16px', '20px']}
            fontWeight={500}
            height={['30px', null, '120px', '120px', '100px']}
          >
            {t('Stake DXD, earn various rewards')}
          </Text>
          <Flex flexDirection="column">
            <Flex
              height={['250px', null, '100%']}
              width={['250px', null, '100%']}
              maxWidth={[null, null, '260px', '100%']}
              flex={[null, null, null, '1']}
              mb={['24px', null, '0']}
            >
              <img width="100%" src="/images/home/farm-2.png" alt="" />
            </Flex>

            <Button mr={['16px', null, '32px']}>
              <RouterLink to="/liquidity">
                <Text color="card" fontSize={['14px', null, '16px']}>
                  {t('Add Now')}
                </Text>
              </RouterLink>
            </Button>
          </Flex>
        </Flex>

        <Flex
          flexDirection="column"
          flex="1"
          ml={[null, null, !reverse && '30px']}
          mr={[null, null, reverse && '30px']}
          alignSelf={['flex-start', null, 'flex-start']}
          width="100%"
        >
          <ColoredWordHeading
            text={t('Farm')}
            fontWeight="700 !important"
            mb="12px"
            fontSize={['22px', null, null, '24px', '26px']}
          />
          <Text
            color="textSubtle"
            mb="24px"
            fontSize={['16px', null, null, '16px', '20px']}
            fontWeight={500}
            height={['30px', null, '120px', '120px', '100px']}
          >
            {t('Stake LP tokens, harvest DXD')}
          </Text>
          <Flex flexDirection="column">
            <Flex
              height={['250px', null, '100%']}
              width={['250px', null, '100%']}
              maxWidth={[null, null, '260px', '100%']}
              flex={[null, null, null, '1']}
              mb={['24px', null, '0']}
            >
              <img width="100%" src="/images/home/farm-1.png" alt="" />
            </Flex>

            <Button mr={['16px', null, '32px']}>
              <RouterLink to="/liquidity">
                <Text color="card" fontSize={['14px', null, '16px']}>
                  {t('Add Now')}
                </Text>
              </RouterLink>
            </Button>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default EarnSection
