import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  ",
        "\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
import styled from "styled-components";
import { space } from "styled-system";
import { Box } from "../Box";
var CardBody = styled(Box).withConfig({
    componentId: "sc-cf8be51c-0"
})(_templateObject(), space);
CardBody.defaultProps = {
    p: "24px"
};
export default CardBody;
