import { useState, useEffect, useRef, useCallback } from 'react'
import styled from 'styled-components'
import { Flex, Box, SwapVertIcon, IconButton } from '@pancakeswap/uikit'
import BigNumber from 'bignumber.js'
import { useTranslation } from '@pancakeswap/localization'

import { DeserializedPool } from 'state/types'
import useIntersectionObserver from 'hooks/useIntersectionObserver'
// import useGetTopFarmsByApr from 'views/Home/hooks/useGetTopFarmsByApr'
// import useGetTopPoolsByApr from 'views/Home/hooks/useGetTopPoolsByApr'
import { vaultPoolConfig } from 'config/constants/pools'
import { useVaultApy } from 'hooks/useVaultApy'
import TopFarmPool from './TopFarmPool'
import RowHeading from './RowHeading'
import decorateFarmpoolLeft from '../images/decorateFarmpoolLeft.png'
import decorateFarmpoolRight from '../images/decorateFarmpoolRight.png'

const WrapFarmsPoolsRow = styled.div`
  padding: 16px;
  background: rgba(255, 255, 255, 0.5);
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(25px);

  border-radius: 15px;
  position: relative;
  overflow: hidden;

  display: flex;
  flex-direction: column;
  align-items: center;

  margin-bottom: 40px;
  ${({ theme }) => theme.mediaQueries.sm} {
    margin-bottom: 100px;
  }

  & > div {
    width: 100%;
    max-width: 1060px;
    margin: 0;
    z-index: 2;
  }

  .decorate-left {
    width: 90px;
    z-index: -1;

    position: absolute;
    top: 30px;
    right: 99%;
  }
  .decorate-right {
    width: 90px;
    z-index: -1;

    position: absolute;
    bottom: 89%;
    left: 97%;
  }
`

const TopTable = styled.div<{ showFarms?: boolean }>`
  height: 260px;

  ${({ theme }) => theme.mediaQueries.sm} {
    height: 200px;
  }

  ${({ theme }) => theme.mediaQueries.lg} {
    height: 100px;
  }

  > div:first-child {
    transition: all 0.4s ease-out;
    opacity: ${({ showFarms }) => (showFarms ? 0 : 1)};
    transform: ${({ showFarms }) => (showFarms ? 'translateY(100%)' : 'translateY(0)')};
  }

  > div:last-child {
    transition: all 0.4s ease-out;
    opacity: ${({ showFarms }) => (showFarms ? 1 : 0)};
    transform: ${({ showFarms }) => (showFarms ? 'translateY(-80%)' : 'translateY(0)')};

    ${({ theme }) => theme.mediaQueries.lg} {
      transform: ${({ showFarms }) => (showFarms ? 'translateY(-100%)' : 'translateY(0)')};
    }
  }
`

const Grid = styled.div`
  display: grid;
  grid-gap: 12px;
  grid-template-columns: repeat(2, 1fr);

  ${({ theme }) => theme.mediaQueries.sm} {
    grid-template-columns: repeat(3, 1fr);
  }

  ${({ theme }) => theme.mediaQueries.lg} {
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 32px;
  }
`

const topFarms: any = [
  {
    lpAddress: '0x58aED290F42963A502626774Bd8fa03f33c9B71f',
    lpSymbol: 'MHUNT-BNB LP',
    pid: 106,
    multiplier: '1X',
    isCommunity: true,
    auctionHostingEndDate: '2022-08-26T08:00:00.000Z',
    quoteTokenPriceBusd: '295.89082888995992159801536948567706421575396660651183140240564513671503173087176701',
    tokenPriceBusd:
      '0.0347967181079123328357921032828761062881444356558257360394961174298095255547555000213735451678233896272652733097679424124318385295472765315254570509683409719903',
    token: {
      chainId: 56,
      decimals: 18,
      symbol: 'MHUNT',
      name: 'MetaShooter',
      isNative: false,
      isToken: true,
      address: '0x2C717059b366714d267039aF8F59125CAdce6D8c',
      projectLink: 'https://metashooter.gg/',
    },
    quoteToken: {
      chainId: 56,
      decimals: 18,
      symbol: 'WBNB',
      name: 'Wrapped BNB',
      isNative: false,
      isToken: true,
      address: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
      projectLink: 'https://www.binance.org',
    },
    userData: {
      allowance: new BigNumber(123),
      tokenBalance: '0',
      stakedBalance: '0',
      earnings: '0',
      proxy: { allowance: new BigNumber(123), tokenBalance: '0', stakedBalance: '0', earnings: '0' },
    },
    tokenAmountTotal: '3785178.86792506530279084',
    quoteTokenAmountTotal: '445.136480063726738476',
    lpTotalInQuoteToken:
      '577.34183511731204470989701418732182966535080170499195458349377040559706721965847945558976941302329904',
    lpTotalSupply: '35675749158626254448230',
    tokenPriceVsQuote: '0.00011759985342720111790616942455972557915096703205988083148614173241473532529803',
    poolWeight: '0.01013171225937183383991894630192502532928064842958459979736575481256332320162107',
    apr: 496.42525739008863,
    lpRewardsApr: 39.58,
  },
  {
    lpAddress: '0xF8cA29a3BF6d34691981D3Ee8D4c9Cd1C437EfeE',
    lpSymbol: 'AOG-PULSENET LP',
    pid: 111,
    multiplier: '1X',
    isCommunity: true,
    auctionHostingEndDate: '2022-08-26T08:00:00.000Z',
    quoteTokenPriceBusd:
      '3.9591679050924534631350208258140638382271990178733508529363265087189159671494095963246953363971696128538303601143283354204146475411858301116906292563907685377604',
    tokenPriceBusd:
      '0.04229906007626506503057041625063358246879040470382506723718648098186104434686964055191547416669699714972631956233217930551671006625391125360351596284904015536981022384080222345936327087631239216111455177761227196157006235201387782329703434',
    token: {
      chainId: 56,
      decimals: 18,
      symbol: 'AOG',
      name: 'AgeOfGods',
      isNative: false,
      isToken: true,
      address: '0x40C8225329Bd3e28A043B029E0D07a5344d2C27C',
      projectLink: 'https://ageofgods.net/',
    },
    quoteToken: {
      chainId: 56,
      decimals: 18,
      symbol: 'PULSENET',
      name: 'PULSENET Token',
      isNative: false,
      isToken: true,
      address: '0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82',
      projectLink: 'https://PULSENET.finance/',
    },
    userData: {
      allowance: new BigNumber(123),
      tokenBalance: '0',
      stakedBalance: '0',
      earnings: '0',
      proxy: { allowance: '0', tokenBalance: '0', stakedBalance: '0', earnings: '0' },
    },
    tokenAmountTotal: '2429985.111561521414763345',
    quoteTokenAmountTotal: '25961.537545847080339093',
    lpTotalInQuoteToken:
      '49693.59856152354567362023959283325795198823959045813473603255833677191588124652604013863350385859687642',
    lpTotalSupply: '176860963627727201745237',
    tokenPriceVsQuote: '0.01068382576597930578015596003553331196092530013459573691241225397752130268184585',
    poolWeight: '0.01013171225937183383991894630192502532928064842958459979736575481256332320162107',
    apr: 431.03648737991995,
    lpRewardsApr: 0,
  },
  {
    lpAddress: '0x07C10ecFb0e1CF81E3e05ddb693Cc114C8EBe498',
    lpSymbol: 'XCAD-BUSD LP',
    pid: 109,
    multiplier: '1X',
    isCommunity: true,
    auctionHostingEndDate: '2022-08-26T08:00:00.000Z',
    quoteTokenPriceBusd: '1',
    tokenPriceBusd: '1.65863790430231655047883313747799167874909748831739481760052525756771583883527416',
    token: {
      chainId: 56,
      decimals: 18,
      symbol: 'XCAD',
      name: 'Chainport.io-Peg XCAD Token',
      isNative: false,
      isToken: true,
      address: '0x431e0cD023a32532BF3969CddFc002c00E98429d',
      projectLink: 'https://xcadnetwork.com/',
    },
    quoteToken: {
      chainId: 56,
      decimals: 18,
      symbol: 'BUSD',
      name: 'Binance USD',
      isNative: false,
      isToken: true,
      address: '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
      projectLink: 'https://www.paxos.com/busd/',
    },
    userData: {
      allowance: new BigNumber(123),
      tokenBalance: '0',
      stakedBalance: '0',
      earnings: '0',
      proxy: { allowance: '0', tokenBalance: '0', stakedBalance: '0', earnings: '0' },
    },
    tokenAmountTotal: '245762.109303081797972768',
    quoteTokenAmountTotal: '407630.349931380447253013',
    lpTotalInQuoteToken:
      '500838.48996767907151592138391520370789242454387977861784509368070253486388386513197305130411022777257628',
    lpTotalSupply: '266123240700163303788808',
    tokenPriceVsQuote: '1.65863790430231655047883313747799167874909748831739481760052525756771583883527416',
    poolWeight: '0.01013171225937183383991894630192502532928064842958459979736575481256332320162107',
    apr: 169.3248521040113,
    lpRewardsApr: 6.1,
  },
  {
    lpAddress: '0xEF642c40EebBc964881dD7Bd1A0b50e90441E73A',
    lpSymbol: 'TRIVIA-BNB LP',
    pid: 107,
    multiplier: '1X',
    isCommunity: false,
    quoteTokenPriceBusd: '295.89082888995992159801536948567706421575396660651183140240564513671503173087176701',
    tokenPriceBusd:
      '0.0306531246964337144555063836832796096058077217604456411461389338153562602247382222299479333727884739640969945468483972503597316732530183599865543411313546139728',
    token: {
      chainId: 56,
      decimals: 3,
      symbol: 'TRIVIA',
      name: 'Trivian Token',
      isNative: false,
      isToken: true,
      address: '0xb465f3cb6Aba6eE375E12918387DE1eaC2301B05',
      projectLink: 'https://trivians.io/',
    },
    quoteToken: {
      chainId: 56,
      decimals: 18,
      symbol: 'WBNB',
      name: 'Wrapped BNB',
      isNative: false,
      isToken: true,
      address: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
      projectLink: 'https://www.binance.org',
    },
    userData: {
      allowance: new BigNumber(123),
      tokenBalance: '0',
      stakedBalance: '0',
      earnings: '0',
      proxy: { allowance: '0', tokenBalance: '0', stakedBalance: '0', earnings: '0' },
    },
    tokenAmountTotal: '14395553.778',
    quoteTokenAmountTotal: '1491.322683729939890421',
    lpTotalInQuoteToken:
      '2502.50567784832403954424687127233544307959251743517669291329108736097324826725651887166082751160327506',
    lpTotalSupply: '4279606540334250',
    tokenPriceVsQuote: '0.00010359606214031538387275788203442881125958723781164426142856509983161830122128',
    poolWeight: '0.01013171225937183383991894630192502532928064842958459979736575481256332320162107',
    apr: 114.52803949144482,
    lpRewardsApr: 30.59,
  },
  {
    lpAddress: '0x25bfD3162360BbD8FF97b86169288b311c2A68D7',
    lpSymbol: 'PEEL-BUSD LP',
    pid: 112,
    multiplier: '1X',
    isCommunity: false,
    quoteTokenPriceBusd: '1',
    tokenPriceBusd: '0.16090619232376745974927139460310661834121433806747679414622297728637223536883688',
    token: {
      chainId: 56,
      decimals: 18,
      symbol: 'PEEL',
      name: 'Meta Apes Peel',
      isNative: false,
      isToken: true,
      address: '0x734548a9e43d2D564600b1B2ed5bE9C2b911c6aB',
      projectLink: 'https://metaapesgame.com/',
    },
    quoteToken: {
      chainId: 56,
      decimals: 18,
      symbol: 'BUSD',
      name: 'Binance USD',
      isNative: false,
      isToken: true,
      address: '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
      projectLink: 'https://www.paxos.com/busd/',
    },
    userData: {
      allowance: new BigNumber(123),
      tokenBalance: '0',
      stakedBalance: '0',
      earnings: '0',
      proxy: { allowance: '0', tokenBalance: '0', stakedBalance: '0', earnings: '0' },
    },
    tokenAmountTotal: '3190441.587019306851289682',
    quoteTokenAmountTotal: '513361.807598674464028208',
    lpTotalInQuoteToken:
      '795262.45525462653859181856813622246376280157993501654556254623967907280771346909481665181398727321350624',
    lpTotalSupply: '1134136211269585954630391',
    tokenPriceVsQuote: '0.16090619232376745974927139460310661834121433806747679414622297728637223536883688',
    poolWeight: '0.01013171225937183383991894630192502532928064842958459979736575481256332320162107',
    apr: 106.63700100694557,
    lpRewardsApr: 31.34,
  },
]
const topPools: any = [
  {
    sousId: 0,
    contractAddress: {
      '56': '0xa5f8C5Dbd5F286960b9d90548680aE5ebFf07652',
      '97': '0xB4A466911556e39210a6bB2FaECBB59E4eB7E43d',
    },
    poolCategory: 'Core',
    tokenPerBlock: '10',
    isFinished: false,
    stakingTokenPrice: 3.9591679050924533,
    earningTokenPrice: 3.9591679050924533,
    apr: 3027.3404797371436,
    stakingToken: {
      chainId: 56,
      decimals: 18,
      symbol: 'PULSENET',
      name: 'PULSENET Token',
      isNative: false,
      isToken: true,
      address: '0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82',
      projectLink: 'https://PULSENET.finance/',
    },
    earningToken: {
      chainId: 56,
      decimals: 18,
      symbol: 'PULSENET',
      name: 'PULSENET Token',
      isNative: false,
      isToken: true,
      address: '0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82',
      projectLink: 'https://PULSENET.finance/',
    },
    userData: {
      allowance: new BigNumber(123),
      stakingTokenBalance: '0',
      stakedBalance: '0',
      pendingReward: '0',
      isLoading: true,
      userShares: 'NaN',
      cakeAtLastUserAction: 'NaN',
      lastDepositedTime: null,
      lastUserActionTime: null,
      lockEndTime: null,
      lockStartTime: null,
      locked: null,
      lockedAmount: 'NaN',
      userBoostedShare: 'NaN',
      currentOverdueFee: '0',
      currentPerformanceFee: '0',
      balance: { cakeAsNumberBalance: null, cakeAsBigNumber: 'NaN', cakeAsDisplayBalance: 'NaN' },
    },
    totalStaked: new BigNumber(123),
    stakingLimit: new BigNumber(123),
    stakingLimitEndBlock: null,
    totalShares: '2811650243882287888178366889',
    pricePerFullShare: '1051001528926681561',
    totalLockedAmount: '180765029514504576896237525',
    totalCakeInVault: '218029272113432318738101234',
    fees: { performanceFee: 200, withdrawalFee: 10, withdrawalFeePeriod: 259200, performanceFeeAsDecimal: 2 },
    vaultKey: 'cakeVault',
  },
  {
    sousId: 294,
    contractAddress: { '56': '0x985a20998c7c59e98005c556393215e39c9a4978', '97': '' },
    poolCategory: 'Core',
    tokenPerBlock: '6.0763',
    version: 3,
    endBlock: 22479160,
    stakingTokenPrice: 3.9591679050924533,
    earningTokenPrice: 0.03996816818742951,
    apr: 134.30019999385217,
    isFinished: false,
    startBlock: 20751160,
    profileRequirement: { required: false, thresholdPoints: '0' },
    stakingToken: {
      chainId: 56,
      decimals: 18,
      symbol: 'PULSENET',
      name: 'PULSENET Token',
      isNative: false,
      isToken: true,
      address: '0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82',
      projectLink: 'https://PULSENET.finance/',
    },
    earningToken: {
      chainId: 56,
      decimals: 18,
      symbol: 'ANKR',
      name: 'Ankr',
      isNative: false,
      isToken: true,
      address: '0xf307910A4c7bbc79691fD374889b36d8531B08e3',
      projectLink: 'https://www.ankr.com/',
    },
    userData: { allowance: '0', stakingTokenBalance: '0', stakedBalance: '0', pendingReward: '0' },
    totalStaked: new BigNumber(123),
    stakingLimit: new BigNumber(123),
    stakingLimitEndBlock: null,
  },
  {
    sousId: 293,
    contractAddress: { '56': '0x8cBCf2f2be93D154be5135f465369Ee6dD84314B', '97': '' },
    poolCategory: 'Core',
    tokenPerBlock: '0.2604',
    version: 3,
    endBlock: 22476760,
    stakingTokenPrice: 3.9591679050924533,
    earningTokenPrice: 1.0112022581449462,
    apr: 119.19430951784219,
    isFinished: false,
    startBlock: 20748760,
    profileRequirement: { required: false, thresholdPoints: '0' },
    stakingToken: {
      chainId: 56,
      decimals: 18,
      symbol: 'PULSENET',
      name: 'PULSENET Token',
      isNative: false,
      isToken: true,
      address: '0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82',
      projectLink: 'https://PULSENET.finance/',
    },
    earningToken: {
      chainId: 56,
      decimals: 18,
      symbol: 'HAY',
      name: 'Hay Stablecoin',
      isNative: false,
      isToken: true,
      address: '0x0782b6d8c4551B9760e74c0545a9bCD90bdc41E5',
      projectLink: 'https://helio.money/',
    },
    userData: { allowance: new BigNumber(0), stakingTokenBalance: '0', stakedBalance: '0', pendingReward: '0' },
    totalStaked: new BigNumber(123),
    stakingLimit: new BigNumber(123),
    stakingLimitEndBlock: null,
  },
  {
    sousId: 292,
    contractAddress: { '56': '0x449EC6B2779A3F63E6c2527CBB162A38Ef199d7D', '97': '' },
    poolCategory: 'Core',
    tokenPerBlock: '0.1071',
    version: 3,
    endBlock: 22391580,
    stakingTokenPrice: 3.9591679050924533,
    earningTokenPrice: 2.1105879057209824,
    apr: 20.08859307598368,
    isFinished: false,
    startBlock: 20663580,
    profileRequirement: { required: false, thresholdPoints: '0' },
    stakingToken: {
      chainId: 56,
      decimals: 18,
      symbol: 'PULSENET',
      name: 'PULSENET Token',
      isNative: false,
      isToken: true,
      address: '0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82',
      projectLink: 'https://PULSENET.finance/',
    },
    earningToken: {
      chainId: 56,
      decimals: 18,
      symbol: 'SFUND',
      name: 'Seedify Fund Token',
      isNative: false,
      isToken: true,
      address: '0x477bC8d23c634C154061869478bce96BE6045D12',
      projectLink: 'https://seedify.fund/',
    },
    userData: { allowance: new BigNumber(123), stakingTokenBalance: '0', stakedBalance: '0', pendingReward: '0' },
    totalStaked: new BigNumber(123),
    stakingLimit: new BigNumber(123),
    stakingLimitEndBlock: null,
  },
  {
    sousId: 291,
    contractAddress: { '56': '0x56D6955Ba6404647191DD7A5D65A5c9Fe43905e1', '97': '' },
    poolCategory: 'Core',
    tokenPerBlock: '1.1574',
    version: 3,
    endBlock: 22016415,
    stakingTokenPrice: 3.9591679050924533,
    earningTokenPrice: 0.1530968566021234,
    apr: 20.085896398137507,
    isFinished: false,
    startBlock: 20288415,
    profileRequirement: { required: false, thresholdPoints: '0' },
    stakingToken: {
      chainId: 56,
      decimals: 18,
      symbol: 'PULSENET',
      name: 'PULSENET Token',
      isNative: false,
      isToken: true,
      address: '0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82',
      projectLink: 'https://PULSENET.finance/',
    },
    earningToken: {
      chainId: 56,
      decimals: 18,
      symbol: 'PSTAKE',
      name: 'pStake Finance',
      isNative: false,
      isToken: true,
      address: '0x4C882ec256823eE773B25b414d36F92ef58a7c0C',
      projectLink: 'https://pstake.finance/',
    },
    userData: { allowance: new BigNumber(123), stakingTokenBalance: '0', stakedBalance: '0', pendingReward: '0' },
    totalStaked: new BigNumber(123),
    stakingLimit: new BigNumber(123),
    stakingLimitEndBlock: null,
  },
]

const FarmsPoolsRow = () => {
  const [showFarms, setShowFarms] = useState(false)
  const { t } = useTranslation()
  const { observerRef, isIntersecting } = useIntersectionObserver()
  // const { topFarms } =  useGetTopFarmsByApr(isIntersecting)
  // const { topPools } =  useGetTopPoolsByApr(isIntersecting)
  const { lockedApy } = useVaultApy()

  const timer = useRef<ReturnType<typeof setTimeout>>(null)
  const isLoaded = topFarms[0] && topPools[0]

  const startTimer = useCallback(() => {
    timer.current = setInterval(() => {
      setShowFarms((prev) => !prev)
    }, 6000)
  }, [timer])

  useEffect(() => {
    if (isLoaded) {
      startTimer()
    }

    return () => {
      clearInterval(timer.current)
    }
  }, [timer, isLoaded, startTimer])

  const getPoolText = (pool: DeserializedPool) => {
    if (pool.vaultKey) {
      return vaultPoolConfig[pool.vaultKey].name
    }

    return t('Stake %stakingSymbol% - Earn %earningSymbol%', {
      earningSymbol: pool.earningToken.symbol,
      stakingSymbol: pool.stakingToken.symbol,
    })
  }

  return (
    <WrapFarmsPoolsRow ref={observerRef}>
      <Flex flexDirection="column" mt="24px">
        <Flex mb="24px" alignItems="center">
          <RowHeading
            text={showFarms ? t('Top Farms') : t('Top Syrup Pools')}
            fontSize={24}
            fontWeight={700}
            lineHeight="36px"
          />
          <IconButton
            variant="text"
            height="100%"
            width="auto"
            onClick={() => {
              setShowFarms((prev) => !prev)
              clearInterval(timer.current)
              startTimer()
            }}
          >
            <SwapVertIcon height="24px" width="24px" color="textSubtle" />
          </IconButton>
        </Flex>
        <TopTable showFarms={showFarms}>
          {/* {showFarms && ( */}
          <Grid>
            {topFarms.map((topFarm, index) => (
              <TopFarmPool
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                title={topFarm?.lpSymbol}
                percentage={topFarm?.apr + topFarm?.lpRewardsApr}
                index={index}
                visible={showFarms}
              />
            ))}
          </Grid>
          {/* )}
          {!showFarms && ( */}
          <Grid>
            {topPools.map((topPool, index) => (
              <TopFarmPool
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                title={topPool && getPoolText(topPool)}
                percentage={topPool?.sousId === 0 ? +lockedApy : topPool?.apr}
                index={index}
                isApy={topPool?.sousId === 0}
                visible={!showFarms}
              />
            ))}
          </Grid>
          {/* )} */}
        </TopTable>
      </Flex>
    </WrapFarmsPoolsRow>
  )
}

export default FarmsPoolsRow
