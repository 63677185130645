import { Flex, Text, Button, Link } from '@pancakeswap/uikit'
import { NextLinkFromReactRouter as RouterLink } from 'components/NextLink'
import { CompositeImageProps } from '../CompositeImage'
import ColoredWordHeading from '../ColoredWordHeading'

interface SalesSectionButton {
  to: string
  text: string
  external: boolean
}

export interface SalesSectionProps {
  headingText: string
  bodyText: any
  reverse: boolean
  primaryButton: SalesSectionButton
  secondaryButton?: SalesSectionButton
  images?: CompositeImageProps
  image: string
  imageWidth?: string
}

const SalesSection: React.FC<SalesSectionProps> = (props) => {
  const { headingText, bodyText, reverse, primaryButton, secondaryButton, images, image, imageWidth } = props

  return (
    <Flex flexDirection="column" position="relative">
      <Flex
        flexDirection={['column', null, reverse ? 'row-reverse' : 'row']}
        alignItems="center"
        justifyContent="center"
      >
        <Flex
          height={['250px', null, '100%']}
          width={['250px', null, '100%']}
          maxWidth={[null, null, '260px', '100%']}
          flex={[null, null, null, '1']}
          mb={['24px', null, '0']}
        >
          <img src={image} alt="" width={imageWidth} />
        </Flex>
        <Flex
          flexDirection="column"
          flex="1"
          ml={[null, null, !reverse && '64px']}
          mr={[null, null, reverse && '64px']}
          alignSelf={['flex-start', null, 'center']}
        >
          <ColoredWordHeading
            text={headingText}
            fontWeight="700 !important"
            mb="12px"
            fontSize={['22px', null, null, '24px', '26px']}
          />
          <Text color="textSubtle" mb="24px" fontSize={['16px', null, null, '16px', '20px']} fontWeight={500}>
            {bodyText}
          </Text>
          <Flex>
            <Button mr={['16px', null, '32px']}>
              {primaryButton.external ? (
                <Link external href={primaryButton.to}>
                  <Text color="card" fontSize={['14px', null, '16px']}>
                    {primaryButton.text}
                  </Text>
                </Link>
              ) : (
                <RouterLink to={primaryButton.to}>
                  <Text color="card" fontSize={['14px', null, '16px']}>
                    {primaryButton.text}
                  </Text>
                </RouterLink>
              )}
            </Button>

            {!!secondaryButton && <Button variant="secondary">{secondaryButton.text}</Button>}
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default SalesSection
