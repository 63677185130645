import { Button, Flex, Text } from '@pancakeswap/uikit'
import InternalLink from 'components/Links'
import styled from 'styled-components'
// import BG from '/images/background.png'
import earth03Image from './images/earth03.png'

const WrapSection = styled('div')<{ src: string }>`
  width: 100%;
  /* margin-top: -200px; */

  background-image: url(${({ src }) => src});
  background-size: cover;
  position: relative;
`

const WrapBackground = ({ children, src }) => {
  return <WrapSection src={src}>{children}</WrapSection>
}

export default WrapBackground
