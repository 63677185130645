import {
  Heading,
  Flex,
  Text,
  Skeleton,
  ChartIcon,
  CommunityIcon,
  SwapIcon,
  LogoWithTextIcon,
  Button,
} from '@pancakeswap/uikit'
import { useTranslation } from '@pancakeswap/localization'
import styled from 'styled-components'

import useTheme from 'hooks/useTheme'
import { formatLocalisedCompactNumber } from 'utils/formatBalance'
import useSWRImmutable from 'swr/immutable'
import armorialIcon from '../images/armorialIcon.png'
import amountIcon from '../images/mount.png'
import girlIcon from '../images/girl.png'
import cupIcon from '../images/cupWin.png'
import userCardIcon from '../images/userCard.png'
import dupCoinCardIcon from '../images/dupCoin.png'
import waweCardIcon from '../images/wawe.png'

import IconCard, { IconCardData } from '../IconCard'
import StatCardContent from './StatCardContent'
// import GradientLogo from '../GradientLogoSvg'
// import Logo from "./components/Logo";

const WideCard = styled.div`
  background: linear-gradient(180deg, rgba(107, 37, 181, 0.2) 0%, rgba(197, 74, 137, 0.2) 106.79%),
    linear-gradient(98.1deg, #ffffff 0.92%, rgba(255, 255, 255, 0) 103.6%);
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);

  padding: 50px 20px;
  margin-bottom: 42px;
  border-radius: 20px;

  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;

  ${({ theme }) => theme.mediaQueries.lg} {
    padding: 50px;
  }

  .title {
    font-size: 22px;

    ${({ theme }) => theme.mediaQueries.sm} {
      font-size: 26px;
    }
  }

  .desc {
    font-size: 14px;

    ${({ theme }) => theme.mediaQueries.sm} {
      font-size: 16px;
    }

    ${({ theme }) => theme.mediaQueries.lg} {
      font-size: 20px;
    }
  }

  > img:nth-child(1) {
    position: absolute;
    width: 80px;
    top: -1rem;
    left: -0.5rem;

    ${({ theme }) => theme.mediaQueries.sm} {
      width: 100px;
      top: -1rem;
      left: -1rem;
    }

    ${({ theme }) => theme.mediaQueries.lg} {
      width: 140px;
      top: -3rem;
      left: -1rem;
    }

    ${({ theme }) => theme.mediaQueries.xl} {
      width: 249px;
      top: -5rem;
      left: -3rem;
    }
  }

  > img:nth-child(2) {
    position: absolute;
    width: 60px;
    bottom: -2rem;
    right: 0;

    ${({ theme }) => theme.mediaQueries.sm} {
      width: 80px;
    }

    ${({ theme }) => theme.mediaQueries.lg} {
      width: 100px;
      bottom: -3rem;
      right: -1.5rem;
    }

    ${({ theme }) => theme.mediaQueries.xl} {
      width: 146px;
      bottom: -1rem;
      right: -5rem;
    }
  }
`

const ListCard = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  > div:not(:last-child) {
    margin-bottom: 24px;
  }

  ${({ theme }) => theme.mediaQueries.sm} {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
  }

  ${({ theme }) => theme.mediaQueries.lg} {
    flex-wrap: nowrap;
    justify-content: space-between;

    > div:not(:last-child) {
      margin-bottom: 0;
    }
  }
`

const Stats = () => {
  const { t } = useTranslation()
  const { theme } = useTheme()

  const { data: tvl } = useSWRImmutable('tvl')
  const { data: txCount } = useSWRImmutable('totalTx30Days')
  const { data: addressCount } = useSWRImmutable('addressCount30Days')
  const trades = formatLocalisedCompactNumber(txCount)
  const users = formatLocalisedCompactNumber(addressCount)
  const tvlString = tvl ? formatLocalisedCompactNumber(tvl) : '-'

  const tvlText = t('And those users are now entrusting the platform with over $%tvl% in funds.', { tvl: tvlString })
  const [entrusting, inFunds] = tvlText.split(tvlString)

  const UsersCardData: IconCardData = {
    // icon: <CommunityIcon color="secondary" width="36px" />,
    icon: <img src={userCardIcon.src} alt="" style={{ minWidth: '191px' }} />,
  }

  const TradesCardData: IconCardData = {
    // icon: <SwapIcon color="primary" width="36px" />,
    icon: <img src={dupCoinCardIcon.src} alt="" style={{ minWidth: '128px' }} />,
  }

  const StakedCardData: IconCardData = {
    // icon: <ChartIcon color="failure" width="36px" />,
    icon: <img src={waweCardIcon.src} alt="" style={{ minWidth: '130px' }} />,
  }

  return (
    <Flex justifyContent="center" alignItems="center" flexDirection="column" pb={['120px', null, '152px']}>
      {/* <GradientLogo height="48px" width="48px" mb="24px" /> */}
      <Flex width="165px" mb="16px" alignItems="center" justifyContent="center">
        <img src="/images/we-dex.png" alt="" style={{ objectFit: 'contain' }} />
      </Flex>
      {/* <LogoWithTextIcon width="165px" mb="16px" /> */}
      <WideCard>
        <img src="/images/home/laptop.png" alt="" />
        <img src="/images/home/rocket.png" alt="" />
        <Text as="p" textAlign="center" className="title" lineHeight="108%" fontWeight={700}>
          {t('Used by millions.')}
        </Text>
        <Text as="p" textAlign="center" className="title" lineHeight="108%" fontWeight={700} mb="16px">
          {t('Trusted with billions.')}
        </Text>
        <Text as="p" textAlign="center" color="textSubtle" fontWeight={500} className="desc">
          {t('We Dex has the most users of any decentralized platform, ever.')}
        </Text>
        <Flex flexWrap="wrap" alignSelf="center">
          <Text
            as="p"
            display="inline"
            textAlign="center"
            color="textSubtle"
            mb="20px"
            fontWeight={500}
            className="desc"
          >
            {entrusting}
            <>{tvl ? <>{tvlString}</> : <Skeleton display="inline-block" height={16} width={70} mt="2px" />}</>
            {inFunds}
          </Text>
        </Flex>

        {/* <Text textAlign="center" color="textSubtle" bold>
          {t('Will you join them?')}
        </Text> */}
        <Flex alignSelf="center">
          <Button
            // as="a"
            // decorator={{
            //   text: 'New',
            //   backgroundColor: '#FBAB33',
            // }}
            // href="/swap"
            variant="primary"
          >
            {t('Will you join them?')}
          </Button>
        </Flex>
        {/* <img className="decorator-button" src={amountIcon.src} alt="" /> */}
      </WideCard>
      <ListCard>
        {[
          {
            title: t('%users% users', { users }),
            bodyText: t('In the last 30 days'),
            color: theme.colors.secondary,
            cardData: UsersCardData,
            background: 'linear-gradient(180deg, #C9D5F8 0%, #E6E9F2 106.79%)',
            top: '5px',
          },
          {
            title: t('%trades% trades', { trades }),
            bodyText: t('Made in the last 30 days'),
            color: '#2297DC',
            cardData: TradesCardData,
            background: 'linear-gradient(180deg, #E8CBE8 0%, #F0D8E0 106.79%)',
          },
          {
            title: t('$%tvl% staked', { tvl: tvlString }),
            bodyText: t('Total Value Locked'),
            color: '#FB5BC9',
            cardData: StakedCardData,
            background: 'linear-gradient(180deg, #E8D7F9 0%, #F1E8F7 106.79%)',
            top: '0px',
          },
        ].map((item, index, arr) => {
          return (
            <IconCard
              key={item.title}
              {...item.cardData}
              // mb={['16px', null, null, '0']}
              background={item.background}
              top={item.top}
            >
              <StatCardContent headingText={item.title} bodyText={item.bodyText} highlightColor={item.color} />
            </IconCard>
          )
        })}
        {/* <IconCard {...UsersCardData} mr={[null, null, null, '36px']} mb={['16px', null, null, '0']}>
          <StatCardContent
            headingText={t('%users% users', { users })}
            bodyText={t('in the last 30 days')}
            highlightColor={theme.colors.secondary}
          />
        </IconCard>
        <IconCard {...TradesCardData} mr={[null, null, null, '36px']} mb={['16px', null, null, '0']}>
          <StatCardContent
            headingText={t('%trades% trades', { trades })}
            bodyText={t('Made in the last 30 days')}
            highlightColor={theme.colors.primary}
          />
        </IconCard>
        <IconCard {...StakedCardData}>
          <StatCardContent
            headingText={t('$%tvl% staked', { tvl: tvlString })}
            bodyText={t('Total Value Locked')}
            highlightColor={theme.colors.failure}
          />
        </IconCard> */}
      </ListCard>
    </Flex>
  )
}

export default Stats
