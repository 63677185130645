import { Button, Flex, Heading, Text } from '@pancakeswap/uikit'
import { useWeb3React } from '@pancakeswap/wagmi'
import ConnectWalletButton from 'components/ConnectWalletButton'
import { NextLinkFromReactRouter } from 'components/NextLink'
import { useTranslation } from '@pancakeswap/localization'
import Image from 'next/image'
import styled, { keyframes } from 'styled-components'
import bunnyImage from '../../../../public/images/home/lunar-bunny/bunny@2x.png'
import CoinImage from '../../../../public/images/home/Coins.png'
import CompositeImage, { CompositeImageProps } from './CompositeImage'
import { SlideSvgDark, SlideSvgLight } from './SlideSvg'

const flyingAnim = () => keyframes`
  from {
    transform: translate(0,  0px);
  }
  50% {
    transform: translate(-5px, -5px);
  }
  to {
    transform: translate(0, 0px);
  }
`

const fading = () => keyframes`
  from {
    opacity: 0.9;
  }
  50% {
    opacity: 0.1;
  }
  to {
    opacity: 0.9;
  }
`

const BgWrapper = styled.div`
  z-index: -1;
  overflow: hidden;
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: 0px;
  left: 0px;
`

const InnerWrapper = styled.div`
  position: absolute;
  width: 100%;
  bottom: -3px;
`

const BunnyWrapper = styled.div`
  width: 300px;
  height: 300px;
  animation: ${flyingAnim} 3.5s ease-in-out infinite;
  will-change: transform;
  > span {
    overflow: visible !important; // make sure the next-image pre-build blur image not be cropped
  }

  ${({ theme }) => theme.mediaQueries.md} {
    width: 100%;
    height: auto;
  }
`

const ContentBox = styled(Flex)`
  > h2 {
    font-size: 32px;
    line-height: 42px;

    ${({ theme }) => theme.mediaQueries.sm} {
      font-size: 36px;
    }

    ${({ theme }) => theme.mediaQueries.lg} {
      font-size: 48px;
      line-height: 52px;
    }
  }

  > p {
    font-size: 16px;

    ${({ theme }) => theme.mediaQueries.lg} {
      font-size: 20px;
      line-height: 30px;
    }
  }
`

const StarsWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;

  & :nth-child(2) {
    animation: ${fading} 2s ease-in-out infinite;
    animation-delay: 1s;
  }

  & :nth-child(3) {
    animation: ${fading} 5s ease-in-out infinite;
    animation-delay: 0.66s;
  }

  & :nth-child(4) {
    animation: ${fading} 2.5s ease-in-out infinite;
    animation-delay: 0.33s;
  }
`

const starsImage: CompositeImageProps = {
  path: '/images/home/lunar-bunny/',
  attributes: [
    { src: 'star-l', alt: '3D Star' },
    { src: 'star-r', alt: '3D Star' },
    { src: 'star-top-r', alt: '3D Star' },
  ],
}

const Hero = () => {
  const { t } = useTranslation()
  const account: any = null

  return (
    <>
      <style jsx global>
        {`
          .slide-svg-dark {
            display: none;
          }
          .slide-svg-light {
            display: block;
          }
          [data-theme='dark'] .slide-svg-dark {
            display: block;
          }
          [data-theme='dark'] .slide-svg-light {
            display: none;
          }
        `}
      </style>
      {/* <BgWrapper>
        <InnerWrapper>
          <SlideSvgDark className="slide-svg-dark" width="100%" />
          <SlideSvgLight className="slide-svg-light" width="100%" />
        </InnerWrapper>
      </BgWrapper> */}
      <Flex
        position="relative"
        flexDirection={['column-reverse', null, null, 'row']}
        alignItems={['flex-end', null, null, 'center']}
        justifyContent={['center', null, null, 'space-between']}
        mt={[account ? '280px' : '50px', null, 0]}
        id="homepage-hero"
      >
        <ContentBox flexDirection="column" width={['100%', null, null, '52%']}>
          <Text as="h2" fontWeight={700} color="secondary" mb="24px">
            {t('The moon is made of pancakes.')}
          </Text>
          <Text as="p" color="secondary" mb={['24px', null, '36px']} fontWeight={500}>
            {t('Trade, earn, and win crypto on the most popular decentralized platform in the galaxy.')}
          </Text>
          <Flex>
            {!account && <ConnectWalletButton mr={['16px', null, '35px']} />}
            <NextLinkFromReactRouter to="/swap">
              <Button variant={!account ? 'secondary' : 'primary'}>{t('Trade Now')}</Button>
            </NextLinkFromReactRouter>
          </Flex>
        </ContentBox>

        <Flex
          height={['100%', null, null, '100%']}
          width={['100%', null, null, '45%']}
          // flex={[null, null, null, '1']}
          mb={['24px', null, null, '0']}
          position="relative"
          justifyContent="center"
          alignItems="center"
        >
          <BunnyWrapper>
            <Image src={CoinImage} priority placeholder="blur" alt="" />
          </BunnyWrapper>
          {/* <StarsWrapper>
            <CompositeImage {...starsImage} />
          </StarsWrapper> */}
        </Flex>
      </Flex>
    </>
  )
}

export default Hero
