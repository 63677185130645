import { Flex, Text, Button, Link } from '@pancakeswap/uikit'
import { NextLinkFromReactRouter as RouterLink } from 'components/NextLink'
import { useTranslation } from '@pancakeswap/localization'

import { CompositeImageProps } from '../CompositeImage'
import ColoredWordHeading from '../ColoredWordHeading'

interface LockupsSectionButton {
  to: string
  text: string
  external: boolean
}

export interface LockupsSectionProps {
  headingText: string
  bodyText: any
  reverse: boolean
  primaryButton: LockupsSectionButton
  secondaryButton?: LockupsSectionButton
  images?: CompositeImageProps
  image: string
  imageWidth?: string
}

const LockupsSection: React.FC<LockupsSectionProps> = (props) => {
  const { t } = useTranslation()
  const { headingText, bodyText, reverse, primaryButton, secondaryButton, images, image, imageWidth } = props

  return (
    <Flex flexDirection="column" position="relative">
      <Flex
        flexDirection={['column', null, reverse ? 'row-reverse' : 'row']}
        alignItems="center"
        justifyContent="center"
      >
        <Flex
          height={['250px', null, '100%']}
          width={['250px', null, '100%']}
          maxWidth={[null, null, '260px', '100%']}
          flex={[null, null, null, '1']}
          mb={['24px', null, '0']}
        >
          <img src="/images/home/lockups.png" alt="" width={imageWidth} />
        </Flex>

        <Flex
          flexDirection="column"
          flex="1"
          ml={[null, null, !reverse && '64px']}
          mr={[null, null, reverse && '64px']}
          alignSelf={['flex-start', null, 'center']}
        >
          <ColoredWordHeading
            text={t('Lock LPs')}
            fontWeight="700 !important"
            mb="12px"
            fontSize={['22px', null, null, '24px', '26px']}
          />
          <Text color="textSubtle" mb="24px" fontSize={['16px', null, null, '16px', '20px']} fontWeight={500}>
            {t(
              'Locking liquidity involves transferring LP tokens to a time-locked smart contract, restricting holders from accessing funds until the lock expires.',
            )}
          </Text>

          <ColoredWordHeading
            text={t('Lock Token')}
            fontWeight="700 !important"
            mb="12px"
            fontSize={['22px', null, null, '24px', '26px']}
          />
          <Text color="textSubtle" mb="24px" fontSize={['16px', null, null, '16px', '20px']} fontWeight={500}>
            {t(
              'Locking team tokens involves securing them in a time-locked decentralized smart contract vault. During this period, project team members or founders cannot withdraw or modify the allocated tokens.',
            )}
          </Text>

          <Flex width="100%" justifyContent="center">
            <Button mr={['16px', null, '32px']}>
              <RouterLink to="/lockups">
                <Text color="card" fontSize={['14px', null, '16px']}>
                  {t('Learn more')}
                </Text>
              </RouterLink>
            </Button>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default LockupsSection
