import { Flex, Text, Button } from '@pancakeswap/uikit'
import { NextLinkFromReactRouter as RouterLink } from 'components/NextLink'
import { useTranslation } from '@pancakeswap/localization'

import { CompositeImageProps } from '../CompositeImage'
import ColoredWordHeading from '../ColoredWordHeading'

interface TradeSectionButton {
  to: string
  text: string
  external: boolean
}

export interface TradeSectionProps {
  headingText: string
  bodyText: any
  reverse: boolean
  primaryButton: TradeSectionButton
  secondaryButton?: TradeSectionButton
  images?: CompositeImageProps
  image: string
  imageWidth?: string
}

const TradeSection: React.FC<TradeSectionProps> = (props) => {
  const { t } = useTranslation()

  const { headingText, bodyText, reverse, primaryButton, secondaryButton, images, image, imageWidth } = props

  return (
    <Flex flexDirection="column" position="relative">
      <Flex flexDirection={['column', null, reverse ? 'row-reverse' : 'row']} style={{ rowGap: '50px' }}>
        <Flex flexDirection="column">
          <ColoredWordHeading
            text={t('Trade')}
            fontWeight="700 !important"
            mb="12px"
            fontSize={['22px', null, null, '24px', '26px']}
          />
          <Flex
            height={['250px', null, '100%']}
            width={['250px', null, '100%']}
            maxWidth={[null, null, '260px', '100%']}
            flex={[null, null, null, '1']}
            mb={['24px', null, '0']}
          >
            <img src={image} alt="" width={imageWidth} />
          </Flex>
        </Flex>

        <Flex
          flexDirection="column"
          flex="1"
          ml={[null, null, !reverse && '64px']}
          mr={[null, null, reverse && '64px']}
          alignSelf={['flex-start', null, 'flex-start']}
          width="100%"
        >
          <ColoredWordHeading
            text={t('Swap')}
            fontWeight="700 !important"
            mb="12px"
            fontSize={['22px', null, null, '24px', '26px']}
          />
          <Text
            color="textSubtle"
            mb="24px"
            fontSize={['16px', null, null, '16px', '20px']}
            fontWeight={500}
            height={['30px', null, '120px', '120px', '100px']}
          >
            {t('Trade hassle-free on BNB Smart Chain instantly without registration.')}
          </Text>
          <Flex flexDirection="column">
            <Flex
              height={['250px', null, '100%']}
              width={['250px', null, '100%']}
              maxWidth={[null, null, '260px', '100%']}
              flex={[null, null, null, '1']}
              mb={['24px', null, '0']}
            >
              <img width="100%" src="/images/home/swap-img.png" alt="" />
            </Flex>

            <Button mr={['16px', null, '32px']}>
              <RouterLink to={primaryButton.to}>
                <Text color="card" fontSize={['14px', null, '16px']}>
                  {primaryButton.text}
                </Text>
              </RouterLink>
            </Button>
          </Flex>
        </Flex>

        <Flex
          flexDirection="column"
          flex="1"
          ml={[null, null, !reverse && '64px']}
          mr={[null, null, reverse && '64px']}
          alignSelf={['flex-start', null, 'flex-start']}
          width="100%"
        >
          <ColoredWordHeading
            text={t('Liquidity')}
            fontWeight="700 !important"
            mb="12px"
            fontSize={['22px', null, null, '24px', '26px']}
          />
          <Text
            color="textSubtle"
            mb="24px"
            fontSize={['16px', null, null, '16px', '20px']}
            fontWeight={500}
            height={['30px', null, '120px', '120px', '100px']}
          >
            {t('Fund liquidity pools, earn trading fees')}
          </Text>

          <Flex flexDirection="column">
            <Flex
              height={['250px', null, '100%']}
              width={['250px', null, '100%']}
              maxWidth={[null, null, '260px', '100%']}
              flex={[null, null, null, '1']}
              mb={['24px', null, '0']}
            >
              <img width="100%" src="/images/home/liquidity-img.png" alt="" />
            </Flex>

            <Button mr={['16px', null, '32px']}>
              <RouterLink to="/liquidity">
                <Text color="card" fontSize={['14px', null, '16px']}>
                  {t('Add Now')}
                </Text>
              </RouterLink>
            </Button>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default TradeSection
