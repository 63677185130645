import { appearAnimation, useMatchBreakpointsContext } from '@pancakeswap/uikit'
import { useWeb3React } from '@web3-react/core'
import { useLayoutEffect, useState } from 'react'
import styled from 'styled-components'
import SwiperCore, { Autoplay, EffectFade, Pagination } from 'swiper'
import 'swiper/css'
import 'swiper/css/effect-fade'
import 'swiper/css/pagination'
import { Swiper, SwiperSlide } from 'swiper/react'
import { useMultipleBannerConfig } from './hooks/useMultipleBannerConfig'

SwiperCore.use([Autoplay])

const BannerPlaceHolder = styled.div<{ walletConnected: boolean }>`
  max-width: 820px;
  margin: 0 auto 65px;
  position: relative;
  /* height: 232px; */

  &::before {
    content: '';
    background: linear-gradient(90.02deg, #6b25b5 0.02%, #c54a89 107.2%);

    /* border: 2px solid #ff9c71; */
    border-radius: 10px;

    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
`

const StyledSwiper = styled(Swiper)`
  position: relative;
  overflow: visible;
  opacity: 0;
  animation: ${appearAnimation} 0.3s ease-in-out 0.7s forwards;
  .swiper-pagination {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: center;
    width: 108px;
    bottom: 12px;
    ${({ theme }) => theme.mediaQueries.sm} {
      bottom: 35px;
    }
    ${({ theme }) => theme.mediaQueries.md} {
      bottom: 45px;
    }
    ${({ theme }) => theme.mediaQueries.lg} {
      bottom: 35px;
    }
  }
  .swiper-pagination-bullet {
    background-color: white;
    flex-basis: 108px;
    margin: 0 !important;
    border-radius: 0px;
    &:first-child {
      border-radius: 4px 0px 0px 4px;
    }
    &:last-child {
      border-radius: 0px 4px 4px 0px;
    }
  }
`

const MultipleBanner: React.FC = () => {
  const bannerList = useMultipleBannerConfig()
  const { account } = useWeb3React()
  const { isDesktop, isTablet } = useMatchBreakpointsContext()
  const [swiperRef, setSwiperRef] = useState<SwiperCore>(null)

  useLayoutEffect(() => {
    if (swiperRef && bannerList.length > 1 && !swiperRef?.autoplay?.running) {
      swiperRef?.autoplay?.start()
    }

    if (swiperRef && bannerList.length <= 1) {
      swiperRef?.autoplay?.stop()
    }
  }, [bannerList, swiperRef])

  return (
    <BannerPlaceHolder walletConnected={Boolean(account)}>
      <StyledSwiper
        onSwiper={setSwiperRef}
        modules={[Autoplay, Pagination, EffectFade]}
        spaceBetween={50}
        observer
        slidesPerView={1}
        effect="fade"
        fadeEffect={{ crossFade: true }}
        speed={500}
        // autoplay={{ delay: 5000, pauseOnMouseEnter: true, disableOnInteraction: false }}
        loop
        pagination={{ clickable: true }}
      >
        {bannerList.map((banner, index) => {
          const childKey = `Banner${index}`
          return (
            <SwiperSlide style={{ padding: 0 }} key={childKey}>
              {banner}
            </SwiperSlide>
          )
        })}
      </StyledSwiper>
    </BannerPlaceHolder>
  )
}

export default MultipleBanner
