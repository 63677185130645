import lotteryImage from './lottery.png'
import IFOImage from './IFO.png'
import Rocket from './Rocket.png'
import giftImage from './gift.png'
import handsImage from './hands.png'
import CoinImage01 from './coin01.png'
import CoinImage02 from './coin02.png'
import loudspeakerImage from './loudspeaker.png'
import IFOMobileImage from './IFOMobile.png'
import competitionImage from './competition.png'
import competitionMobileImage from './competitionMobile.png'
import modImage from './mod-tc-desktop.png'
import modMobileImage from './mod-tc-mobile.png'
import lotteryMobileImage from './lotteryMobile.png'
import modWhiteLogo from './mod-white-logo.png'
import perpetualImage from './perpetual.png'
import perpetualMobileImage from './perpetualMobile.png'

export {
  lotteryImage,
  IFOImage,
  Rocket,
  giftImage,
  handsImage,
  CoinImage01,
  CoinImage02,
  loudspeakerImage,
  competitionImage,
  modImage,
  modMobileImage,
  lotteryMobileImage,
  competitionMobileImage,
  IFOMobileImage,
  modWhiteLogo,
  perpetualImage,
  perpetualMobileImage,
}
