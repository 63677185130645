import { TranslateFunction } from '@pancakeswap/localization'
import { SalesSectionProps } from '.'

export const swapSectionData = (t: TranslateFunction): SalesSectionProps => ({
  headingText: t('Trade anything. No registration, no hassle.'),
  bodyText: t('Trade any token on BNB Smart Chain in seconds, just by connecting your wallet.'),
  reverse: false,
  primaryButton: {
    to: '/swap',
    text: t('Trade Now'),
    external: false,
  },
  secondaryButton: {
    to: 'https://docs.pancakeswap.finance/',
    text: t('Learn'),
    external: true,
  },
  image: '/images/home/trade-anything.png',
})

export const earnSectionData = (t: TranslateFunction): SalesSectionProps => ({
  headingText: t('Earn passive income with crypto.'),
  bodyText: t('We Dex makes it easy to make your crypto work for you.'),
  reverse: true,
  primaryButton: {
    to: '/farms',
    text: t('Explore'),
    external: false,
  },
  secondaryButton: {
    to: 'https://docs.pancakeswap.finance/products/yield-farming',
    text: t('Learn'),
    external: true,
  },
  image: '/images/home/earn-passive.png',
})

export const cakeSectionData = (t: TranslateFunction): SalesSectionProps => ({
  headingText: t('CAKE makes our world go round.'),
  bodyText: t(
    'CAKE token is at the heart of the We Dex ecosystem. Buy it, win it, farm it, spend it, stake it... heck, you can even vote with it!',
  ),
  reverse: false,
  primaryButton: {
    to: '/swap?outputCurrency=0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82',
    text: t('Buy CAKE'),
    external: false,
  },
  secondaryButton: {
    to: 'https://docs.pancakeswap.finance/tokenomics/cake',
    text: t('Learn'),
    external: true,
  },
  image: '/images/home/cake-section.png',
})

export const startSectionData = (t: TranslateFunction): SalesSectionProps => ({
  headingText: t('Start in seconds.'),
  bodyText: t('Connect your crypto wallet to start using the app in seconds.'),
  reverse: true,
  primaryButton: {
    to: '/farms',
    text: t('Connect Wallet'),
    external: false,
  },
  // secondaryButton: {
  //   to: 'https://docs.pancakeswap.finance/products/yield-farming',
  //   text: t('Learn'),
  //   external: true,
  // },
  image: '/images/home/start-section.png',
  imageWidth: '640',
})
