import { memo } from 'react'
import Image from 'next/image'
import styled, { keyframes } from 'styled-components'
import { Button, Text } from '@pancakeswap/uikit'
import { NextLinkFromReactRouter } from 'components/NextLink'

import { giftImage, IFOImage, handsImage, loudspeakerImage, CoinImage01, CoinImage02, Rocket } from './images'
import * as S from './Styled'

const shineAnimation = keyframes`
	0% {transform:translateX(-100%);}
  20% {transform:translateX(100%);}
	100% {transform:translateX(100%);}
`

const RightWrapper = styled.div`
  position: absolute;
  right: 1px;
  bottom: 18px;

  display: none;

  ${({ theme }) => theme.mediaQueries.md} {
    bottom: -45px;
    right: 1px;
    display: block;
  }
  ${({ theme }) => theme.mediaQueries.lg} {
    bottom: -45px;
    right: 1px;
  }

  .coin01 {
    width: 50px;
    height: 56px;
    position: absolute;
    top: 10px;
    right: 300px;
    animation: upAndDown 2s linear infinite;
  }
  .coin02 {
    width: 30px;
    height: 36px;
    position: absolute;
    top: 180px;
    right: 270px;
    z-index: 2;
    animation: upAndDown 6s linear infinite;
  }

  @keyframes upAndDown {
    0% {
      transform: translateY(-10px);
    }
    50% {
      transform: translateY(10px);
    }
    100% {
      transform: translateY(-10px);
    }
  }
`
const GiftIconImage = styled.div<{ src: string }>`
  position: absolute;
  background-image: ${({ src }) => `url("${src}")`};
  background-size: cover;
  background-repeat: no-repeat;
  width: 35px;
  height: 35px;
  bottom: 35px;
  right: 95px;
  overflow: hidden;
  border-radius: 50%;
  z-index: 2;
  ${({ theme }) => theme.mediaQueries.sm} {
    width: 80px;
    height: 80px;
    bottom: 40px;
    right: 304px;
    z-index: 2;
  }
  &::after {
    content: '';
    top: 0;
    transform: translateX(100%);
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
    animation: ${shineAnimation} 5s infinite 1s;
    background: -webkit-linear-gradient(
      left,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.8) 50%,
      rgba(128, 186, 232, 0) 99%,
      rgba(125, 185, 232, 0) 100%
    );
  }
`

const IFOBanner = () => {
  return (
    <S.Wrapper>
      <S.Inner>
        <S.LeftWrapper>
          <S.StyledSubheading>
            {/* <img src={loudspeakerImage.src} alt="" /> */}
            Perpetual Futures
          </S.StyledSubheading>
          <S.StyledHeading scale="xl">TRADE UP TO GET GIFT</S.StyledHeading>
          <NextLinkFromReactRouter to="/swap" className="s-link-join">
            <Button style={{ background: 'transparent', border: '1px solid #FFF' }}>
              {/* <Text bold fontSize="16px" mr="4px"> */}
              Join Now
              {/* </Text> */}
              {/* <img src={handsImage.src} alt="" style={{ width: '40px', objectFit: 'contain' }} /> */}
            </Button>
          </NextLinkFromReactRouter>
        </S.LeftWrapper>
        <RightWrapper>
          {/* <GiftIconImage
            src={giftImage.src}
            onError={(event) => {
              // @ts-ignore
              // eslint-disable-next-line no-param-reassign
              event.target.style.display = 'none'
            }}
          /> */}
          {/* <img className="coin01" src={CoinImage01.src} alt="" />
          <img className="coin02" src={CoinImage02.src} alt="" /> */}

          <Image src={Rocket} alt="" width={298} height={297} placeholder="blur" />
        </RightWrapper>
      </S.Inner>
    </S.Wrapper>
  )
}

export default memo(IFOBanner)
