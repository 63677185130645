import { useMemo } from 'react'
import shuffle from 'lodash/shuffle'
import IFOBanner from '../IFOBanner'

/**
 * make your custom hook to control should render specific banner or not
 * add new campaign banner easily
 *
 * @example
 * ```ts
 *  {
 *    shouldRender: isRenderIFOBanner,
 *    banner: <IFOBanner />,
 *  },
 * ```
 */
export const useMultipleBannerConfig = () => {
  return useMemo(
    () =>
      shuffle(
        [
          {
            shouldRender: true,
            banner: <IFOBanner />,
          },
        ]
          .filter((d) => d.shouldRender)
          .map((d) => d.banner),
      ),
    [],
  )
}
